import React, { useState } from 'react';
import './style/Modal.css';
import { Person, Email, Cake, Phone, LocationOn } from '@mui/icons-material';
import WcIcon from '@mui/icons-material/Wc';

const Modal = ({ initialData, onClose, onSave }) => {
  const [formData, setFormData] = useState(initialData || {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={onClose}>×</button>
        <form onSubmit={handleSubmit} className="edit-form">
          <div className="form-group">
            <label htmlFor="name"><Person /> Имя Фамилия:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email"><Email /> Email почта:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="birthDate"><Cake /> Дата рождения:</label>
            <input
              type="text"
              id="birthDate"
              name="birthDate"
              value={formData.birthDate || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber"><Phone /> Номер телефона:</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="gender"><WcIcon/>Пол:</label>
            <input
              type="text"
              id="gender"
              name="gender"
              value={formData.gender || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="deliveryAddress"><LocationOn /> Адрес доставки:</label>
            <input
              type="text"
              id="deliveryAddress"
              name="deliveryAddress"
              value={formData.deliveryAddress || ''}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="save-button">Сохранить изменения</button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
