import React from 'react';
import Map from './Map';
import './style/Contact.css';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';

const Contact = () => {
    return (
        <div className="contact-container">
            <div className="breadcrumbs">
                <span><a href="/">Главная</a></span> &gt; <span>Контакты</span>
            </div>
            <h1>Контакты</h1>
            <div className="contact-info">
                <div className="contact-block">
                    <AccountBalanceIcon className="contact-icon" />
                    <div className="contact-text">
                        <p>Бухгалтерия, склад</p>
                        <a href="tel:+77058212619">+7 705 8212 619</a>
                    </div>
                </div>
                <div className="contact-block">
                    <LoyaltyIcon className="contact-icon" />
                    <div className="contact-text">
                        <p>Вопросы по системе лояльности</p>
                        <a href="tel:+77757163397">+7 775 716 33 97</a>
                    </div>
                </div>
            </div>
            <h2 className="section-title">Наши магазины</h2>
            <div>
                <button className="city-button">г.Астана</button>
            </div>
            <div className="store-list">
                <div className="store">
                    <div className="store-info">
                        <div className="store-name">
                            <p>ВОСХОД</p>
                        </div>
                        <div className="store-address">
                            <LocationOnIcon className="store-icon" />
                            <p>ул. Абылайхана 10</p>
                        </div>
                        <div className="store-phone">
                            <PhoneIcon className="store-icon" />
                            <a href="tel:+77772315990">+7 777 231 59 90</a>
                        </div>
                    </div>
                </div>
                {/* Добавьте дополнительные магазины здесь */}
            </div>
            <div className="map-container">
                <Map />
            </div>
        </div>
    );
};

export default Contact;
