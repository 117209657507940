// src/components/ProductList.js

import React from 'react';
import ProductItem from './ProductItem'; // Импортируем компонент ProductItem
import './style/ProductList.css'; // Стили для списка товаров

function ProductList() {
  return (
    <div className="product-list">
      <ProductItem />
      <ProductItem />
      <ProductItem />
      <ProductItem />
      <ProductItem />
      <ProductItem />
      <ProductItem />
      <ProductItem />
    </div>
  );
}

export default ProductList;
