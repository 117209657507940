import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import Business from '@mui/icons-material/Business';
import Phone from '@mui/icons-material/Phone';
import LocationCity from '@mui/icons-material/LocationCity';
import Public from '@mui/icons-material/Public';
import './style/CreatingAUser.css';

function CreatingAUser() {
  return (
    <div className="create-user-container">
      <div className="user-section">
        <h2 className="create-user-title">Регистрация физического лица</h2>
        <form className="create-user-form">
          <TextField label="Имя" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <AccountCircle className="input-icon" />, }} />
          <TextField label="Фамилия" variant="outlined" fullWidth margin="normal" />
          <TextField label="Электронная почта" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Email className="input-icon" />, }} />
          <TextField label="Телефон" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Phone className="input-icon" />, }} />
          <TextField label="Адрес" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <LocationCity className="input-icon" />, }} />
          <TextField label="Страна" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Public className="input-icon" />, }} />
          <TextField label="Пароль" type="password" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Lock className="input-icon" />, }} />
          <Button variant="contained" color="primary" type="submit" className="submit-button">Создать аккаунт</Button>
        </form>
      </div>
      <div className="user-section">
        <h2 className="create-user-title">Регистрация юридического лица</h2>
        <form className="create-user-form">
          <TextField label="Название компании" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Business className="input-icon" />, }} />
          <TextField label="ИНН" variant="outlined" fullWidth margin="normal" />
          <TextField label="Контактное лицо" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <AccountCircle className="input-icon" />, }} />
          <TextField label="Электронная почта" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Email className="input-icon" />, }} />
          <TextField label="Телефон" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Phone className="input-icon" />, }} />
          <TextField label="Юридический адрес" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <LocationCity className="input-icon" />, }} />
          <TextField label="Страна регистрации" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Public className="input-icon" />, }} />
          <TextField label="Пароль" type="password" variant="outlined" fullWidth margin="normal" InputProps={{ startAdornment: <Lock className="input-icon" />, }} />
          <Button variant="contained" color="primary" type="submit" className="submit-button">Создать аккаунт</Button>
        </form>
      </div>
    </div>
  );
}

export default CreatingAUser;
