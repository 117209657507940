import React from 'react';
import './style/Cart.css'; // Подключаем стили

const Cart = () => (
  <div className="cart-container">
    <div className="breadcrumbs">
      <a href="/">Главная</a> &gt; <span>Корзина</span>
    </div>
    <h2 className="cart-title">Корзина</h2>
    <div className="cart-upper-actions">
      <input type="checkbox" id="select-all" className="select-all-checkbox" />
      <label htmlFor="select-all" className="select-all-label">Выделить все</label>
      <button className="delete-selected">Удалить выбранное</button>
    </div>
    <div className="cart-items-container">
      {[1, 2, 3].map((item, index) => (
        <div className="cart-item" key={index}>
          <input type="checkbox" className="cart-item-select" />
          <div className="cart-item-image"></div>
          <div className="cart-item-info">
            <span className="cart-item-title">Название товара {item}</span>
            <span className="cart-item-price">{(item + 1) * 1000},00 Т</span>
          </div>
          <div className="cart-item-quantity-container">
            <button className="quantity-button">-</button>
            <span className="quantity-number">2</span>
            <button className="quantity-button">+</button>
            <span className="item-total-price">{(item + 1) * 1000 * 2},00 Т</span>
          </div>
        </div>
      ))}
    </div>
    <div className="cart-summary">
      <div className="summary-item">Товаров<span>3</span></div>
      <div className="summary-item">Скидка<span>-100,00 Т</span></div>
      <div className="summary-total">Итог<span>200,00 Т</span></div>
      <button className="checkout-button">Оформить заказ</button>
      <div className="minimum-amount">Минимальная сумма заказа 1000Т</div>
    </div>
  </div>
);

export default Cart;
