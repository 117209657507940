import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ProductBlockAside from './ProductBlockAside';
import './style/CardProduct.css';

const CardProduct = () => {
  return (
    <div className="card-product">
      <div className="breadcrumb-container">
        <div className="breadcrumbs">
          <a href="/">Главная</a> &gt; <span>Каталог</span> &gt; <span>Молоко, сыр, яйца</span> &gt; <span>Название товара</span>
        </div>
        <h1 className="product-title">Название товара</h1>
      </div>
      <div className="product-header">
        <div className="product-art">арт. 371431</div>
        <button className="favorite-button"><FavoriteBorderIcon /> в избранное</button>
      </div>
      <div className="product-main">
        <div className="product-gallery">
          <div className="thumbnails">
            {/* Вставьте здесь реальные изображения или плейсхолдеры */}
            <div className="thumbnail"></div>
            <div className="thumbnail"></div>
            <div className="thumbnail"></div>
            <div className="thumbnail"></div>
            <div className="thumbnail"></div>
            {/* ... */}
          </div>
          <div className="main-image">
            {/* Вставьте здесь реальное большое изображение или плейсхолдер */}
          </div>
        </div>
        <div className="card-product-details">
          {/* Блок цен и характеристик */}
          <div className="product-pricing">
            <div className="tovar-name">
                <div className="original-price">Цена товара</div>
                <div className="current-price">Цена товара</div>
            </div>
            <button className="add-to-cart-btn">В корзину</button>
          </div>
          <div className="product-properties">
            {/* Блок характеристик */}
            <div className="brand-row">
              <span className="property-name">Бренд</span>
              <span className="property-value">ПРОСТОКВАШИНО</span>
            </div>
            <div className="country-row">
              <span className="property-name">Странапроизводителя</span>
              <span className="property-value">Казахстан</span>
            </div>
            <div className="package-row">
              <span className="property-name">Упаковка</span>
              <span className="property-value">200 г</span>
            </div>
          </div>
        </div>
        <div className='PBAside'>
          <ProductBlockAside/>
          <ProductBlockAside/>
          <ProductBlockAside/>
          <ProductBlockAside/>
        </div>
      </div>
    
    </div>
  );
};

export default CardProduct;
