import React from 'react';
import './style/OrdersPage.css';

const OrdersPage = () => {
  return (
    <div className="orders-page">
      <header>
        {/* Содержимое шапки */}
      </header>

      <div className="breadcrumbs">
        <span><a href="/">Главная</a></span> &gt; <span>Заказы</span>
      </div>

      <main className="orders-content">
        <h1>Заказы</h1>
        <div className="order-list">
          <div className="order-card">
            <div className="order-details">
              <div className="order-date">01.04.2021</div>
              <div className="order-time">11:00-14:00</div>
              <div className="order-status">В процессе</div>
            </div>
            <div className="order-actions">
              <button className="order-view">Просмотреть заказ</button>
              <div className="order-price">1 006,00 Т</div>
              <button className="order-delivery">Когда доставить</button>
            </div>
          </div>
          <div className="order-card">
            <div className="order-details">
              <div className="order-date">15.09.2020</div>
              <div className="order-time">14:00-18:00</div>
              <div className="order-status status-not-delivered">Не доставили</div>
            </div>
            <div className="order-actions">
              <button className="order-view">Просмотреть заказ</button>
              <div className="order-price">1 321,20 Т</div>
              <button className="order-delivery delivery-order">Заказать</button>
            </div>
          </div>
          <div className="order-card">
            <div className="order-details">
              <div className="order-date">15.09.2020</div>
              <div className="order-time">18:00-20:00</div>
              <div className="order-status status-returned">Возврат</div>
            </div>
            <div className="order-actions">
              <button className="order-view">Просмотреть заказ</button>
              <div className="order-price">1589,68 Т</div>
              <button className="order-delivery delivery-order">Заказать</button>
            </div>
          </div>
          <div className="order-card">
            <div className="order-details">
              <div className="order-date">15.09.2021</div>
              <div className="order-time">14:00-18:00</div>
              <div className="order-status status-received">Получен</div>
            </div>
            <div className="order-actions">
              <button className="order-view">Просмотреть заказ</button>
              <div className="order-price">3 006,83 Т</div>
              <button className="order-delivery delivery-order">Заказать</button>
            </div>
          </div>
          {/* Повторите для каждого заказа */}
        </div>
        <button className="show-more">Показать ещё</button>
      </main>

      <footer>
        {/* Содержимое подвала */}
      </footer>
    </div>
  );
};

export default OrdersPage;
