// src/components/Header.js

import React, { useState } from 'react';
import './style/Header.css';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';



function Header({ onAuthClick }) {
  
  
  const [showCategories, setShowCategories] = useState(false); // Состояние для отображения всплывающего окна с категориями

  // Категории товаров
  const categories = [<Link to="/ResultsCatalog" className="ResultsCatalog">Мясо</Link>, 'Фрукты', 'Овощий', 'Молочное изделие', 'Хлеб', 'Бакалея', 'Напитки', 'Замороженные продукты', 'Чай, кофе', 'Детское питание'];



  
  return (
    <header className="header" >
      <div className="logo"><a href="/">Логотип</a></div>
      <div>
      <div
        className="category-dropdown"
        onMouseEnter={() => setShowCategories(true)} // Показываем всплывающее окно при наведении
        onMouseLeave={() => setShowCategories(false)} // Скрываем всплывающее окно при уходе курсора
      >
        Категории товаров
        {showCategories && (
          <div className="categories-popup">
            {categories.map((category, index) => (
              <div key={index} className="category-item">
                {category}
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
      <div className="search-input">
      <div style={{ maxWidth: '300px' }}>
        <Input
          type="text"
          placeholder="Найти товар"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton >
                  <Link to="/SearchingResults" className="SearchingResults"><SearchIcon /></Link>
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      </div>
      
      <div className="navigation-icons">
        <Link to="/Favourites" className="favorites-btn">Избранное</Link>
        <Link to="/OrdersPage" className="orders">Заказы</Link>
        <Link to="/Cart" className="cart">Корзина</Link>
      </div>
      <div className="user-info">
        <div className="username" onClick={onAuthClick}><input type="button" value="Авторизация"/></div>
        <div className="user-dropdown"></div>
      </div>
    </header>
  );
}

export default Header;
