import React, { useState } from 'react';
import './style/UserProfile.css';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import GenderIcon from '@mui/icons-material/Wc';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Используем другую иконку
import Modal from './Modal'; // Предполагается, что у вас есть компонент Modal



const UserProfile = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    name: 'Имя Фамилия',
    email: 'email@example.com',
    birthDate: '01.01.1990',
    phoneNumber: '+1234567890',
    gender: 'Мужской',
    deliveryAddress: 'Улица, дом, квартира'
  });
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleProfileUpdate = (updatedData) => {
    setProfileData(updatedData);
  };


  return (
    <div className="profile-container">
      <div className="profile-header">
        <span><a href="/">Главная</a></span> &gt; <span>Каталог</span>
      </div>
      <div className="profile-info">
        <div className="profile-NB">
          <h2><AccountCircleIcon className="name-icon" /> Имя Фамилия</h2>
          <button onClick={openModal}>
            <EditIcon /> Редактировать
          </button>
          {isModalOpen && (
        <Modal
          initialData={profileData}
          onClose={closeModal}
          onSave={handleProfileUpdate}
        />
      )}
        </div>
        <div className="avatar-info">
          <div className="user-photo">
            <AddPhotoAlternateIcon className="add-photo-icon" />
          </div>
          <div className="user-details">
            <div className="detail-item"><EmailIcon className="detail-icon" /><span>Email почта:</span></div>
            <div className="detail-item"><CakeIcon className="detail-icon" /><span>Дата рождения:</span></div>
            <div className="detail-item"><PhoneIcon className="detail-icon" /><span>Номер телефона:</span></div>
            <div className="detail-item"><GenderIcon className="detail-icon" /><span>Пол:</span></div>
            <div className="detail-item"><LocationOnIcon className="detail-icon" /><span>Адрес доставки:</span></div>
          </div>
        </div>
      </div>
      {/* {isModalOpen && <Modal onClose={closeModal} />} */}
    </div>
  );
}

export default UserProfile;
