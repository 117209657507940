// src/components/Articles.js

import React from 'react';
import Article from './Article';
import './style/Articles.css'; // Путь к CSS-файлу статей

function Articles() {
  // Здесь может быть массив статей, полученный из API или другого источника
  const articles = [
    // Заглушка для примера
    { id: 1, date: '05.03.2021', title: 'Режим использования масок и перчаток', summary: 'Подробная информация...' },
    { id: 2, date: '05.03.2021', title: 'Весеннее настроение для каждой', summary: '8 Марта не просто...' },
    { id: 3, date: '05.03.2021', title: 'ЗОЖ или ФАСТФУД. А вы на чьей стороне? Голосуем!', summary: 'Голосуйте за люб...' },
    // ...и так далее для других статей
  ];

  return (
    <div className="articles-container">
      {articles.map((article) => (
        <Article key={article.id} date={article.date} title={article.title} summary={article.summary} />
      ))}
    </div>
  );
}

export default Articles;
