// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './style/Footer.css'; // Импорт стилей

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <a href="/about">О компании</a>
          <Link to="/Contact" className="Contact">Контакты</Link>
          <a href="/vacancies">Вакансии</a>
          <a href="/articles">Статьи</a>
          <a href="/privacy">Политика обработки персональных данных</a>
        </div>
        <div className="footer-section social-media">
          <a href="http://instagram.com">Instagram</a>
          <a href="http://vk.com">VK</a>
          <a href="http://facebook.com">Facebook</a>
          <a href="http://ok.ru">OK</a>
        </div>
        <div className="footer-section">
          <span className="phone-number">8 800 777 33 33</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
