// src/components/AuthorizationModal.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style/AuthorizationModal.css';

function AuthorizationModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({ phone: '', password: '' });

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Форма отправлена:', formData);
    // Здесь может быть код для отправки данных на сервер
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2 className="modal-title">Вход</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              name="phone"
              placeholder="Телефон"
              className="form-input"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <input 
              type="password"
              name="password"
              placeholder="Пароль"
              className="form-input"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="button-group">
            <button type="submit" className="submit-button">
              Войти
            </button>
            <Link to="/CreatingAUser" className="CreatingAUser">
            <button type="button" className="create-user-button" onClick={onClose}>
              Создать пользователя
            </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AuthorizationModal;
