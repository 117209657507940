import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import AuthorizationModal from './components/AuthorizationModal';
import OrdersPage from './components/OrdersPage';
import Cart from './components/Cart';
import Favourites from './components/Favourites';
import CardProduct from './components/CardProduct';
import Contact from './components/Contact';
import UserProfile from './components/UserProfile';
import LegalEntity from './components/LegalEntity';
import SearchingResults from './components/SearchingResults';
import ResultsCatalog from './components/ResultsCatalog';
import CreatingAUser from './components/CreatingAUser';
import DeliveryForm from './components/DeliveryForm';
import './App.css';


function App() {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const toggleAuthModal = () => {
    setAuthModalOpen(!isAuthModalOpen);
  };

  return (
    <Router>
      <div className="app-container"> 
        <Header onAuthClick={toggleAuthModal} />
        <div className="main-container">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="Orderspage" element={<OrdersPage />} />
            <Route path="Favourites" element={<Favourites />} />
            <Route path="Cart" element={<Cart />} />
            <Route path="CardProduct" element={<CardProduct />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="UserProfile" element={<UserProfile />} />
            <Route path="LegalEntity" element={<LegalEntity />} />
            <Route path="SearchingResults" element={<SearchingResults />} />
            <Route path="ResultsCatalog" element={<ResultsCatalog />} />
            <Route path="CreatingAUser" element={<CreatingAUser />} />
            <Route path="DeliveryForm" element={<DeliveryForm />} />
            {/* Add more routes here */}
          </Routes>
        </div>
        <AuthorizationModal isOpen={isAuthModalOpen} onClose={toggleAuthModal} />
        <div className="footer-container"> {/* Добавляем класс к div обертке */}
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
