// src/components/Article.js

import React from 'react';
import './style/Article.css'; // Путь к CSS-файлу статьи

function Article({ date, title, summary }) {
  return (
    <div className="article-card">
      <div className="article-image"></div>
      <div className="article-content">
        <div className="article-date">{date}</div>
        <h3 className="article-title">{title}</h3>
        <p className="article-summary">{summary}</p>
        <button className="button-more">Подробнее</button>
      </div>
    </div>
  );
}

export default Article;
